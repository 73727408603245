/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        $("#header-scroll").click(function() {
          $('html, body').animate({
              scrollTop: $(".site-header")[0].scrollHeight
          }, 800);
        });

        $('select').selectpicker({
          dropupAuto: false
        });

        $('.simple-meat-display-block--wrapper').click(function() {
          // $('.simple-meats-info--wrapper').children().hide(500);

          if($('.is-mobile').css('display') == 'block') {
            $('html, body').animate({
              scrollTop: $('.simple-meats-info--wrapper').offset().top
            }, 1000);
          }
          var trigger = $(this).attr('data-melrina-trigger');
          $('.simple-meat-display-block--wrapper.active').removeClass('active');
          var $this = $(this);

          if($('.simple-meats-info--wrapper').children(':visible').length == 0) {
              // action when all are hidden
              
              $(trigger).stop(true, true).slideDown(500);
              setTimeout(function() {
                $this.addClass('active');
              }, 250);
              
          } else {
            // Children are visible
            $('.simple-meats-info--wrapper').children().hide(500);
            $('.simple-meat-display-block--wrapper.active').removeClass('active');

            setTimeout(function() {
              $(trigger).stop(true, true).slideDown(500);
              $this.addClass('active');
            }, 500, trigger);
          }
        });

        var melrina_block_count = 1;

        $('.block-sidebar-counter--wrapper').each(function(index, value) {
          console.log('hello');
          var count_element = $(this).find('.number');
          count_element.text(melrina_block_count);
          melrina_block_count += 1;
        });

        $(".block-sidebar-arrow--wrapper").click(function() {
          var parent = $(this).closest('.melrina-scroll-block-item');
          if($(this).hasClass('up')) {
            var scrollto = parent.offset().top - parent.outerHeight(true);

            $('html, body').animate({
              scrollTop: scrollto
            }, 800);
          } else if($(this).hasClass('down')) {
            var scrollto = parent.offset().top + parent.outerHeight(true);

            $('html, body').animate({
              scrollTop: scrollto
            }, 800);
            $("html, body").animate({ }, 500);
          }
        });

          /**
           * Load Gallery Lightboxes
           */
          $('a.fl-gallery').featherlightGallery({
            previousIcon: '&#9664;',     /* Code that is used as previous icon */
            nextIcon: '&#9654;',         /* Code that is used as next icon */
            galleryFadeIn: 100,          /* fadeIn speed when slide is loaded */
            galleryFadeOut: 300          /* fadeOut speed before slide is loaded */
          });

        //   $('.pb--slider').owlCarousel({
        //     items: 1,
        //     nav: true
        // });

        $('.pb--slider').slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          fade: true,
          asNavFor: '.pb--slider_nav'
        });
        
        $('.pb--slider_nav').slick({
          slidesToShow: 7,
          slidesToScroll: 1,
          asNavFor: '.pb--slider',
          dots: false,
          arrows: false,
          focusOnSelect: true,
          infinite: true
        });

        $('.pb--slider_arrow--wrapper').on('click', function() {
          if($(this).hasClass('prev')) {
            $('.pb--slider').slick('slickPrev');
          }
          if($(this).hasClass('next')) {
            $('.pb--slider').slick('slickNext');
          }
        })

        $('.brands-slider').owlCarousel({
          responsive: {
            0: {
              items: 2,
              nav: false,
              dots: false,
              center: true,
              loop: true,
              autoplay: true,
              slideTransition: 'linear',
              autoplayTimeout: 6000,
              autoplaySpeed: 6000,
              autoplayHoverPause: false,
            },
            568: {
              items: 3,
              nav: false,
              dots: false,
              margin: 15,
              center: true,
              loop: true,
              autoplay: true,
              slideTransition: 'linear',
              autoplayTimeout: 6000,
              autoplaySpeed: 6000,
              autoplayHoverPause: false,
            },
            768: {
              items: 4,
              nav: false,
              dots: false,
              margin: 15,
              center: true,
              loop: true,
              autoplay: true,
              slideTransition: 'linear',
              autoplayTimeout: 6000,
              autoplaySpeed: 6000,
              autoplayHoverPause: false,
            },
            992: {
              items: 6,
              nav: false,
              dots: false,
              margin: 15,
              center: true,
              loop: true,
              autoplay: true,
              slideTransition: 'linear',
              autoplayTimeout: 6000,
              autoplaySpeed: 6000,
              autoplayHoverPause: false,
            }
          }
        })

        var html = $('html');

        $('.header--menu').on('click', function() {
            html.removeClass('loading').toggleClass('nav-open');
        });

        $('.nav-mobile-primary-close--wrapper').on('click', function() {
            html.removeClass('nav-open');
        });

        $('.melrina-section-link').on('click', function() {
          html.removeClass('nav-open');
        });

        function toggleNavDrop(e) {
          var parent = e.target.closest('.nav-item');
          parent.classList.toggle('active');
        }

        var navDropIcons = document.querySelectorAll('.nav-drop-icon');

        navDropIcons.forEach(function(elem) {
          elem.addEventListener('click', toggleNavDrop, false);
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        AOS.init({
          disable: 'mobile'
        });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'page_template_template_frequently_asked_questions': {
      init: function() {
        function toggleFaqDrop(e) {
          var parent = e.target.closest('.faq--wrapper');
          parent.classList.toggle('active');
        }
        var faqWrappers = document.querySelectorAll('.faq--wrapper');

        faqWrappers.forEach(function(elem) {
          elem.addEventListener('click', function() {
            elem.classList.toggle('active');
          })
        })
        // var navDropIcons = document.querySelectorAll('.faq-toggle');

        // navDropIcons.forEach(function(elem) {
        //   elem.addEventListener('click', toggleFaqDrop, false);
        // });
      }
    },
    'page_template_template_contact': {
      init: function() {

        
        
      }
    },
    'single_post': {
      init: function() {
        $('.single-blog-gallery--wrapper').owlCarousel({
          items: 1,
          dots: true,
          arrows: false
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
